<template>
    <list-condition-template ref="table" :rows="rows" :total="total"
     @page-info-change="handlePageInfoChange" v-model:listQuery="listQuery"
     :defaultSort="{prop:'updateTime',order:'descending'}" :index-column="true">
    <template #condition>
      <el-form-item>
        <div class="demo-date-picker">
          <div class="block">
            <span class="demonstration">筛选日期：</span>
            <el-date-picker
              clearable
              value-format="YYYY-MM-DD"
              v-model="valueDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-input @keyup.enter="handleFilter" placeholder="输入订单批次查询" v-model="listQuery.condition.keyword" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
      </el-form-item>
      <el-row>
        <el-button class="filter-item" type="success" v-waves icon="el-icon-plus" size="mini" @click="addOrderClick">新建分发单</el-button>
      </el-row>
    </template>
    <template #columns>
        <el-table-column min-width="150px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="接收时间" align="center" property="receiptTime">
          <template  #default="scope">
            <span>{{scope.row.receiptTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="防伪标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="采购条码数（枚）" property="barcodeNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.barcodeNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="70px" label="未分发制造商（家）" property="notDisManufacturerNum" align="center">
          <template #default="scope">
            <span>{{scope.row.notDisManufacturerNum}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250px" class-name="fixed-width">
          <template  #default="scope">
            <el-button size="mini" type="primary" @click="handleShow(scope.row)" v-waves>查看</el-button>
            <el-button size="mini" type="success" @click="handleTransact(scope.row)" v-waves>分发</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="startupFormDialogShow" :append-to-body="true" width="80%">
        <component :is="startupForm" v-model:show="startupFormDialogShow" v-model:orderId="orderId" @close="startupFormDialogShow=false;getList()"></component>
    </el-dialog>

  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import inDistributeListApi from '@/apis/distribute/inDistributeList'
import addDistributeForm from './addDistribute'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate,
    addDistributeForm
  },
  data () {
    return {
      valueDate: undefined,
      rows: null,
      total: 0,
      orderId: undefined,
      startupFormDialogShow: false,
      startupForm: null,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startReceiptTime: undefined,
          endReceiptTime: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      }
    }
  },
  filters: {
  },
  methods: {
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      inDistributeListApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      this.$router.push({name:this.$router.routesConfig.distributeDetails.name, params:{id:row.id, isShow: 'show'}})
    },
    /**
     * 搜索
     */
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startReceiptTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endReceiptTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startReceiptTime = undefined
        this.listQuery.condition.endReceiptTime = undefined
      }
      this.getList()
    },
    addOrderClick(){
      this.startupForm = 'addDistributeForm'
      this.startupFormDialogShow = true
    },
    handleTransact(row){
      //跳转到分发页面
      this.$router.push({name:this.$router.routesConfig.distributeDetails.name, params:{id:row.id, isShow: 'distribute'}})
    }
  }
}
</script>
